import React from 'react'
import styles from './index.module.css'
import { Grid, Typography, Link } from '@material-ui/core'
import { useStaticQuery } from 'gatsby'
import get from 'lodash/get'
import FollowAt from './social-media-follow'

export default ({}) => {
  const data = useStaticQuery(graphql`
    query FollowUsQuery {
      allContentfulSocialCondividi {
        edges {
          node {
            id
            name
            link
            icon {
              fluid(maxWidth: 1200, background: "rgb:000000") {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
  `)
  const socialCondividi = get(data, 'allContentfulSocialCondividi.edges')

  return (
    <div className={styles.container}>
      <div className={styles.gridContainer}>
        <Grid container justify={'center'}>
          <Grid item xs={2} lg={2}>
            <Typography variant={'h3'}>Seguici su</Typography>
          </Grid>
          <Grid item lg={12}>
            <Grid container justify={'center'}>
              <Grid item xs={12} lg={6}>
                <FollowAt
                  links={socialCondividi.map(({ node: social }) => social.link)}
                  iconSize={4}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
