import React from 'react'
import Header from '../header'
import Footer from '../footer'
import { MuiThemeProvider } from '@material-ui/core'
import theme from '../theme'
import './index.module.css'
import '../../../assets/css/avenir.css'
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

class Template extends React.Component {
  render() {
    const { location, children, banner } = this.props
    const queryClient = new QueryClient();

    return (
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={theme}>
          <Header banner={banner} location={location} />
          {children}
          <Footer />
        </MuiThemeProvider>
      </QueryClientProvider>
    )
  }
}

export default Template
