import React from 'react'
import { withStyles } from '@material-ui/core'
import extractDomain from 'extract-domain'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Facebook from './icons/Facebook'
import Twitter from './icons/Twitter'
import Youtube from './icons/Youtube'
import Instagram from './icons/Instagram'
import Tumblr from './icons/Tumblr'
import Github from './icons/Github'
import LinkedIn from './icons/LinkedIn'
import cn from 'classname'

const getIcon = (domain) => {
  switch (domain) {
    case 'facebook.com':
      return Facebook
    case 'twitter.com':
      return Twitter
    case 'youtube.com':
      return Youtube
    case 'instagram.com':
      return Instagram
    case 'tumblr.com':
      return Tumblr
    case 'github.com':
      return Github
    case 'linkedin.com':
      return LinkedIn
    default:
      return
  }
}

const styles = ({ color, hoverColor, hoverMove, spacing }) => {
  return {
    socialContainer: {
      marginTop: '20px !important'
    },
    social: {
      margin: '0 1rem !important',
      transition: 'transform 250ms !important',
      display: '',
      opacity: color && !hoverColor && '0.8 !important',
      '&:hover': {
        color: hoverColor,
        opacity: 1,
        transform:
          (hoverMove === undefined && 'translateY(-2px)') ||
          (hoverMove ? 'translateY(-2px)' : ''),
      },
    },
    'facebook.com': {
      color: color || '#4968ad !important',
    },
    'linkedin.com': {
      color: color || '#4968ad !important',
    },
    'youtube.com': {
      color: color || '#eb3223 !important',
    },
    'twitter.com': {
      color: color || '#49a1eb !important',
    },
    'instagram.com': {
      color: color || 'black !important',
    },
    'tumblr.com': {
      color: color || '#35465c !important',
    },
    'github.com': {
      color: color || 'black !important',
    },
  }
}

const FollowAt = ({ classes, links, iconSize }) => {
  return (
    <div className={classes.socialContainer}>
      {links.map((link) => {
        let socialMedia = String(extractDomain(link)).toLowerCase()
        let icon = getIcon(socialMedia)
        return (
          <a
            key={socialMedia}
            href={link}
            className={cn(classes.social, classes[socialMedia])}
          >
            <FontAwesomeIcon icon={icon} size={`${iconSize}x`} />
          </a>
        )
      })}
    </div>
  )
}

export default withStyles(styles)(FollowAt)
