import React from 'react'
import FollowUs from '../follow-us'
import Newsletter from '../newsletter'
import styles from './index.module.css'
import { Button, Grid, Typography, Link, Divider } from '@material-ui/core'
import logo from '../../../assets/img/MRF-logo.png'
import cn from 'classname'
import { useStaticQuery } from 'gatsby'
import get from 'lodash/get'

export default ({}) => {
  const disabled = true
  const row1 = [
    {
      label: 'News',
      href: '/lista/articoli/1/',
    },
    {
      label: 'Primo piano',
      href: '/',
    },
    {
      label: 'Politica',
      href: '/politica/1/',
    },
    !disabled
      ? {
          label: "Cosa ne pensi dell'iniziativa",
          href: '/',
        }
      : null,
    {
      label: 'Documenti',
      href: '/documenti/',
    },
    !disabled
      ? {
          label: 'Iscriviti',
          href: '/iscriviti/',
        }
      : null,
  ]
  const row2 = [
    {
      label: 'Video',
      href: '/lista/video/1/',
    },
    !disabled
      ? {
          label: 'Comunicati',
          href: '/comunicati/',
        }
      : null,
    {
      label: 'Lavoro',
      href: '/lavoro/1/',
    },
    !disabled
      ? {
          label: 'Collabora con noi',
          href: '/',
        }
      : null,
    {
      label: 'Informativa Privacy',
      href: '/privacy',
    },
    !disabled
      ? {
          label: 'Contatti',
          href: '/contatti/',
        }
      : null,
  ]
  const row3 = [
    {
      label: 'Sanità',
      href: '/sanita/1/',
    },
    null,
    {
      label: 'Legalità',
      href: '/legalita/1/',
    },
    {
      label: 'Chi siamo',
      href: '/',
    },
    null,
    null,
  ]
  const row4 = [
    !disabled
      ? {
          label: 'Emergenza Coronavirus, quali le vostre difficoltà?',
          href: '/emergenza-covid/',
        }
      : null,
    null,
    {
      label: 'Fiscalità',
      href: '/fiscalita/1/',
    },
    null,
    null,
    null,
  ]
  const row5 = [
    null,
    null,
    {
      label: 'Economia',
      href: '/economia/1/',
    },
    null,
    null,
    null,
  ]

  const data = useStaticQuery(graphql`
    query LegalInfo {
      contentfulInformazioniLegali {
        email1
        email2
        indirizzo
        numeroTelefonico
        nome
        pIva
      }
    }
  `)
  const legalInfo = get(data, 'contentfulInformazioniLegali')
  return (
    <div className={styles.container}>
      <FollowUs />
      {!disabled && <Newsletter />}
      <div className={styles.linksContainer}>
        <div className={styles.gridContainer}>
          <Grid container justify={'center'}>
            <Grid item lg={2}>
              {!disabled && (
                <Button className={styles.buttonDonation}>DONA AL MRF</Button>
              )}
            </Grid>
            <Grid item container lg={12} spacing={2}>
              {row1.map((rowElement, index) => (
                <Grid
                  key={`footer-1-${index}-${rowElement && rowElement.label}`}
                  item
                  lg={2}
                >
                  {rowElement ? (
                    <Link className={styles.link} href={rowElement.href}>
                      {rowElement.label}
                    </Link>
                  ) : null}
                </Grid>
              ))}
              {row2.map((rowElement, index) => (
                <Grid
                  key={`footer-2-${index}-${rowElement && rowElement.label}`}
                  item
                  lg={2}
                >
                  {rowElement ? (
                    <Link className={styles.link} href={rowElement.href}>
                      {rowElement.label}
                    </Link>
                  ) : null}
                </Grid>
              ))}
              {row3.map((rowElement, index) => (
                <Grid
                  key={`footer-3-${index}-${rowElement && rowElement.label}`}
                  item
                  lg={2}
                >
                  {rowElement ? (
                    <Link className={styles.link} href={rowElement.href}>
                      {rowElement.label}
                    </Link>
                  ) : null}
                </Grid>
              ))}
              {row4.map((rowElement, index) => (
                <Grid
                  key={`footer-4-${index}-${rowElement && rowElement.label}`}
                  item
                  lg={2}
                >
                  {rowElement ? (
                    <Link className={styles.link} href={rowElement.href}>
                      {rowElement.label}
                    </Link>
                  ) : null}
                </Grid>
              ))}
              {row5.map((rowElement, index) => (
                <Grid
                  key={`footer-5-${index}-${rowElement && rowElement.label}`}
                  item
                  lg={2}
                >
                  {rowElement ? (
                    <Link className={styles.link} href={rowElement.href}>
                      {rowElement.label}
                    </Link>
                  ) : null}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={styles.logoContainer}>
        <div className={cn(styles.bottomBorderSection, styles.gridContainer)}>
          <Grid container alignItems={'center'} justify={'center'}>
            <Grid item lg={2} className={styles.gridLogo}>
              <img src={logo} alt={'Logo'} className={styles.logo} />
              <Typography variant={'body1'}>© 2020</Typography>
            </Grid>
            <Grid item lg={2}>
              <Button
                classes={{
                  root: styles.buttonGreen,
                }}
                onClick={() => window.location.href = '/iscriviti/'}
              >
                Iscriviti
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.firstRowLegalInfo}>
          <Typography>{`${legalInfo.nome} - ${legalInfo.indirizzo} - ${legalInfo.pIva}`}</Typography>
        </div>
        <div className={styles.secondRowLegalInfo}>
          <Typography>{legalInfo.numeroTelefonico}</Typography>
          <Divider
            className={styles.divider}
            orientation={'vertical'}
            flexItem
          />
          <Typography>{legalInfo.email1}</Typography>
          <Divider
            className={styles.divider}
            orientation={'vertical'}
            flexItem
          />
          <Typography>{legalInfo.email2}</Typography>
        </div>
      </div>
    </div>
  )
}
