import search from '../../../assets/img/search.svg'
import React, { Fragment, useCallback } from 'react'
import logo from '../../../assets/img/MRF-logo.png'
import linkedinLogo from '../../../assets/img/linkedin.png'
import styles from './index.module.css'
import {actions} from '.././../redux-store/reducer'

import {
  Grid,
  Button,
  Link,
  Typography,
  Popover,
  ButtonBase,
} from '@material-ui/core'
import cn from 'classname'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'

export default ({ banner, location: { pathname } }) => {
  const dispatch = useDispatch();

  const isLogged = useSelector(state => state.user.isLogged)

  // STUFF DISABLED FOR NOW, LATER IMPLMEMENTED
  const disabled = true

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = !!anchorEl

  const sections = [
    {
      label: 'news',
      href: '/',
      isSelected: pathname === '/',
    },
    {
      label: 'video',
      href: '/lista/video/1/',
      isSelected: pathname.includes('/video'),
    },
    {
      label: 'sanità',
      href: '/sanita/1/',
      isSelected: pathname.includes('/sanita'),
    },
    {
      label: 'politica',
      href: '/politica/1/',
      isSelected:
        pathname.includes('/politica') ||
        pathname.includes('/lavoro') ||
        pathname.includes('/legalita') ||
        pathname.includes('/fiscalita') ||
        pathname.includes('/economia'),
      subMenu: [
        {
          label: 'lavoro',
          href: '/lavoro/1/',
        },
        {
          label: 'legalità',
          href: '/legalita/1/',
        },

        {
          label: 'fiscalità',
          href: '/fiscalita/1/',
        },
        {
          label: 'economia',
          href: '/economia/1/',
        },
      ],
    },
    /* disabled for now
    {
      label: 'cosa ne pensi',
      isSelected: pathname.includes('/cosa-pensi')
    }
     */
  ]

  const handleShowLoginDialog = useCallback((() => {
    dispatch(actions.setShowLoginDialog(true));
  }), [])

  return (
    <div className={styles.header}>
      <div className={styles.headerInfo}>
        <div className={styles.gridContainer}>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              <Grid container alignItems="center">
                <img
                  src={logo}
                  alt={'Logo'}
                  className={banner ? styles.logoBanner : styles.logo}
                />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {!disabled && (
                <Button className={styles.buttonDonation}>DONA AL MRF</Button>
              )}
            </Grid>
            <Grid
              item
              xs={4}
              container
              justify="flex-end"
              alignItems={'baseline'}
            >
              {
                !isLogged && (<Button
                  classes={{
                    root: styles.buttonGreen,
                  }}
                  className={styles.iscrivitHeader}
                  onClick={() => window.location.href = '/iscriviti/'}
                >
                  Iscriviti
                </Button>)
              }
              <Link
                className={styles.link}
                variant="subtitle1"
                href={'/documenti/'}
              >
                DOCUMENTI
              </Link>
              {!disabled && (
                <Link className={styles.link} variant="subtitle1">
                  CONTATTI
                </Link>
              )}
              {!disabled && (
                <Link className={styles.link} variant="subtitle1">
                  <img
                    alt="search"
                    src={search}
                    className={styles.searchIcon}
                  />
                  CERCA
                </Link>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
      {banner && (
        <div className={styles.headerContent}>
          <div className={styles.gridContainer}>
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item lg={12}>
                <Typography variant="h1" className={styles.title}>
                  {banner && banner.titolo}
                </Typography>
              </Grid>
              <Grid item lg={12} className={styles.description}>
                <Typography variant="h2">
                  {banner && banner.descrizione.descrizione}
                </Typography>
              </Grid>
              {
                !isLogged && (<Grid item xs={12}>
                  <Grid container justify="center">
                    <Button
                      classes={{
                        root: styles.buttonGreen,
                      }}
                      onClick={() => window.location.href = '/iscriviti/'}
                    >
                      Iscriviti
                    </Button>
                  </Grid>
                </Grid>)
              }
              {
                !isLogged && (<Grid
                  item
                  lg={12}
                  container
                  alignItems="center"
                  justify="center"
                >
                  <Typography
                    className={styles.signupLable}
                    variant="subtitle1"
                  >
                    Sei già iscritto?
                  </Typography>
                  <Link className={styles.signupLink} onClick={handleShowLoginDialog}>Accedi</Link>
                </Grid>)
              }
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Button
                    classes={{
                      root: styles.buttonLinkedin,
                      label: styles.buttonLinkedinLabel
                    }}
                    target="_blank"
                    href="https://www.linkedin.com/company/movimentorivoluzionefiscale/"
                  >
                    <span>Seguici su</span>
                    <img alt="" src={linkedinLogo} className={styles.linkedin} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      <div className={styles.headerNavigation}>
        <div className={styles.buttonsContainer}>
          <div className={styles.gridContainer}>
            <Grid container spacing={2} alignItems="center">
              {sections.map((section) => (
                <Grid
                  item
                  xs={3}
                  lg={2}
                  key={`section-${section.label}`}
                  className={styles.buttonContainer}
                >
                  <Button
                    className={cn(
                      styles.buttonSection,
                      section.isSelected && styles.buttonSectionSelected
                    )}
                    onClick={() => (window.location.href = section.href)}
                  >
                    {section.label}
                  </Button>
                  {section.subMenu ? (
                    <Fragment>
                      <ButtonBase
                        className={cn(styles.buttonSection)}
                        onClick={handleClick}
                      >
                        <ExpandMoreIcon />
                      </ButtonBase>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        classes={{
                          paper: styles.popOverContainer,
                        }}
                      >
                        {section.subMenu.map((subMenuElement) => (
                          <div
                            key={`section-${subMenuElement.label}`}
                            className={styles.subMenuButtonContainer}
                          >
                            <Button
                              variant={'body1'}
                              className={styles.subMenuButton}
                              onClick={() =>
                                (window.location.href = subMenuElement.href)
                              }
                            >
                              {subMenuElement.label}
                            </Button>
                          </div>
                        ))}
                      </Popover>
                    </Fragment>
                  ) : null}
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  )
}
