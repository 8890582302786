import React from 'react'
import { createMuiTheme } from '@material-ui/core'

export default createMuiTheme({
  typography: {
    h1: {
      fontSize: '2.875rem',
      fontFamily: 'Oswald',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      fontFamily: 'Avenir',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontFamily: 'Oswald',
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: '1.1rem',
      fontFamily: 'Avenir',
      fontWeight: 350,
    },
    button: {
      fontSize: '1.125rem',
      fontFamily: 'Avenir Black',
    },
    body1: {
      fontFamily: 'Avenir Book',
      fontSize: '1rem',
      lineHeight: 1.375,
    },
  },
})
